import React from "react";

function Footer() {
  return (
    <footer>
      {/* <div className="container text-center">
        <p className="float-left">CodingProjects.io</p>
      </div> */}
    </footer>
  );
}

export default Footer;
